import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('layout.navbar');
@Component({
    selector: 'wiz-layout-navbar',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/layout.navbar/view.scss */
body {
  width: 100%;
}`],
})
export class LayoutNavbarComponent {
}

export default LayoutNavbarComponent;