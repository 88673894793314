import templateSource from './view.html';
              import { Component } from '@angular/core';

import Wiz from 'src/wiz';
let wiz = new Wiz('/wiz').app('component.nav');
import { Input } from '@angular/core';

@Component({
    selector: 'wiz-component-nav',
template: templateSource || '',
    styles: [`

/* file: /mnt/data/wiz/project/main/build/src/app/component.nav/view.scss */
body {
  width: 100%;
}`],
})
export class ComponentNavComponent {
    @Input() title = "Wiznav";

    public isMenuCollapsed: boolean = true;

    constructor() { }

    view = (() => {
        let obj: any = {};

        obj.data = "";

        obj.click = async () => {
            alert(obj.data);
        }

        return obj;
    })();

}

export default ComponentNavComponent;